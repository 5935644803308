<template>
  <div>
    <v-container>
      <v-row justify="center" class="view-row">
        <!-- 준비중 페이지 -->
        <v-col cols="12" class="prepare" v-if="form.sampleType.value === 'A형'" style="margin-top: 50px; margin-bottom: 80px;">
          <v-row justify="center">
            <v-col cols="12" class="text-center a-logo">
              <!-- 임시 준비중 이미지 -->
              <img :src="form.attachment[1].url" 
                    style="max-width: 200px;" 
                    v-if="form.attachment[1].url !== null && 
                          form.attachment[1].url !== undefined &&
                          form.attachment[1].url !== '' 
                        "
              />
              <span v-else>로고 없음</span>
            </v-col>
            <v-col cols="12" class="text-center a-hospital a-hospital-intro">
              <!-- 병원명, 의사명 -->
              안녕하세요. <span>{{ memberInfo.hospNm }} {{ memberInfo.drNm }} 원장</span>입니다.
            </v-col>
            <v-col cols="12" class="text-center a-ing">
              현재 페이지 제작중입니다.
            </v-col>
            <v-col cols="12" class="a-hospital-num-print text-center">
              <div class="a-hospital-num-title a-title">전화번호</div>
              <div class="a-hospital-num-con a-contents">
                <!-- 전화번호 -->
                {{ form.tel.value ? form.tel.value : "전화번호 미기재" }}
              </div>
            </v-col>
            <v-col cols="12" class="a-hospital-locate-title-print text-center">
              <!-- 주소, 세부 주소 -->
              <div class="a-hospital-locate-title a-title">주소</div>
              <div class="a-hospital-locate-con a-contents">
                {{ memberInfo.addr }} {{ memberInfo.addrDtl }}
              </div>
            </v-col>
            <v-col cols="12" v-if="memberInfo.addr" class="hospital-locate">
              <v-row>
                <v-col cols="12" >
                  <naver-map
                    v-if="mapLocation.posX !== 1 && loadMap"
                    v-bind:posX="mapLocation.posX"
                    v-bind:posY="mapLocation.posY"
                    v-bind:address="memberInfo.addr + ' ' + memberInfo.addrDtl"
                    v-bind:hospNm="memberInfo.hospNm"
                    v-bind:propsHeight="mapSize.height"
                  ></naver-map>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- 자동완성 페이지 -->
        <v-col
          cols="12"
          md="8"
          lg="8"
          xl="8"
          v-if="form.sampleType.value === 'B형'"
          class="doctor-pr"
          id="doctor-pr"
        >
          <v-row justify="center">
            <v-col cols="12">
              <v-row align="center" class="pt-16 doctor-pr-con">
                <v-col cols="6" class="text-right pr-16 hospital-logo">
                  <!-- 로고 -->
                  <v-img
                    :src="form.attachment[1].url"
                    max-height="100%"
                    contain
                    class="hospital-logo-img"
                    v-if="form.attachment[1].url !== null && 
                            form.attachment[1].url !== undefined &&
                            form.attachment[1].url !== '' 
                          "
                  >
                  </v-img>
                  <span v-else>로고 없음</span>
                </v-col>
                <v-col cols="6" class="text-h4 pl-16 hospital-name">
                  <!-- 병원명 -->
                  {{ memberInfo.hospNm }}
                </v-col>
                <v-col cols="12" class="text-center hospital-img">
                  <!-- 병원사진 -->
                  <v-avatar
                    color="indigo lighten-5"
                    rounded="xl"
                    width="95%"
                    height="500"
                    class="pt-2 pb-2 hospital-img-con"
                  >
                    <v-img
                      :src="form.attachment[2].url"
                      class="ma-5 hospital-img-view"
                      max-width="100%"
                      cover
                      v-if="form.attachment[2].url !== null && 
                            form.attachment[2].url !== undefined &&
                            form.attachment[2].url !== '' 
                          "
                    >
                    </v-img>
                  </v-avatar>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                style="position: relative; bottom: 100px"
                class="doctor-top-info"
              >
                <v-col cols="3" class="text-left pl-16 doctor-img">
                  <!-- 의사 프로필 사진 -->
                  <v-avatar
                    rounded="circle"
                    height="220px"
                    width="220px"
                    style="
                      border: 15px solid white;
                      z-index: 100;
                      background-color: #e0e0e0;
                    "
                    class="dr-profile-img"
                  >
                    <v-img 
                      :src="form.attachment[0].url" max-height="100%" cover
                      v-if="form.attachment[0].url !== null && 
                            form.attachment[0].url !== undefined &&
                            form.attachment[0].url !== '' 
                          "
                    >
                    </v-img>
                    <v-img 
                      v-else
                      src="@/assets/images/sample_person.png" max-height="100%" cover>
                    </v-img>
                  </v-avatar>
                </v-col>
                <v-col
                  cols="9"
                  class="text-left pb-10 doctor-name doctor-name-col"
                  align-self="end"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-h4 font-weight-bold justify-end doctor-name"
                    >
                      <span>{{ memberInfo.drNm }} 원장</span>
                    </v-col>
                    <v-col
                      cols="12"
                      class="hospital-link"
                      v-if="form.homepage.value !== null && form.homepage.value !== ''"
                    >
                      <span>
                        <img
                          src="@/assets/images/icons/paperclip-solid.svg"
                          style="margin-right: 10px"
                        />
                        <span
                          style="color: #146f83; cursor: pointer;"
                          @click="openHomepage"
                        >
                          {{ form.homepage.value }}
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="print-row-up">
                <!-- v-if="keywordList[0] !== undefined" -->
                <v-col cols="12" v-if="keywordList.length > 0">
                  <v-list>
                    <v-list-item>
                      <v-list-item-content class="Medical-subject">
                        <v-list-item-title class="Medical-subject-title title">
                          진료과목
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div></div>
                  <div>
                    <!-- 키워드 리스트 -->
                    <span
                      class="keyword-span"
                      v-for="(k, index) in keywordList"
                      :key="index + 'k'"
                    >
                      <v-chip
                        class="keyword-chip"
                        v-if="k !== '' && k !== null"
                        color="#146f83"
                        outlined
                      >
                        {{ k }}
                      </v-chip>
                    </span>
                  </div>
                </v-col>
                <v-col cols="12" v-if="form.introduceHospital.value" class="hospital-info">
                  <div class="hospital-info-title title">병원소개</div>
                  <div class="hospital-info-con contents">
                    <!-- 병원소개 -->
                    {{ form.introduceHospital.value }}
                  </div>
                </v-col>
                <v-col cols="12" v-if="form.treatmentTime.value" class="Treatment-time">
                  <div class="Treatment-time-title title">진료시간</div>
                  <!-- 진료시간 -->
                  <div
                    class="Treatment-time-con contents"
                    v-html="computedLine(form.treatmentTime.value)"
                  />
                </v-col>
                <v-col cols="12" v-if="pr.addr" class="hospital-locate">
                  <div class="hospital-locate-title title">주소</div>
                  <!-- 병원 위치 -->
                  <div class="hospital-locate-con contents">
                    {{ memberInfo.addr }} {{ memberInfo.addrDtl }}
                  </div>
                  <v-row>
                    <v-col cols="12" >
                      <naver-map
                        v-if="mapLocation.posX !== 1 && loadMap"
                        v-bind:posX="mapLocation.posX"
                        v-bind:posY="mapLocation.posY"
                        v-bind:address="memberInfo.addr + ' ' + memberInfo.addrDtl"
                        v-bind:hospNm="memberInfo.hospNm"
                        v-bind:propsHeight="mapSize.height"
                      ></naver-map>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" v-if="form.tel.value" class="hospital-num">
                  <div class="hospital-num-title title">전화번호</div>
                  <div class="hospital-num-con contents">
                    <!-- 전화번호 -->
                    {{ form.tel.value }}
                  </div>
                </v-col>
                <v-col cols="6" v-if="form.fax.value" class="hospitla-fax">
                  <div class="hospitla-fax-title title">팩스</div>
                  <div class="hospital-fax-con contents">
                    <!-- 팩스 -->
                    {{ form.fax.value }}
                  </div>
                </v-col>
                <!-- <v-col cols="6" v-if="form.homepage.value" class="hospital-num hospital-cursor" @click="openHomepage()">
                  <div class="hospital-num-title title">홈페이지</div>
                  <div class="hospital-num-con contents">
                    {{ form.homepage.value }}
                  </div>
                </v-col> -->
                <v-col cols="6" v-if="form.resvPage.value" class="hospitla-fax hospital-cursor" @click="openRevPage()">
                  <div class="hospitla-fax-title title">예약페이지</div>
                  <div class="hospital-fax-con contents">
                    <!-- 예약페이지 -->
                    {{ form.resvPage.value }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- 디자인 페이지 -->
        <v-col cols="12" v-if="form.sampleType.value === 'C형'" class="text-center">
          <!-- pr.prAttchId 디자인 주소값을 통해 서버에서 그림파일을 가져옴 -->
          <img 
            id="b-type-image" 
            class="b-type-image" 
            :src="form.attachment[4].url" 
            v-if="form.attachment[4].url !== null && 
                  form.attachment[4].url !== undefined &&
                  form.attachment[4].url !== '' 
                "
            style="max-width: 1000px;"
            />
        </v-col>
      </v-row>
    </v-container>
    <default-popup v-if="checkPopup" :popupSet="popupSet" 
      @printPr="printPrpage"
    />
  </div>
</template>

<script>
import NaverMap from "@/components/NaverMap";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: {
    NaverMap,
    DefaultPopup,
  },
  data() {
    return {
      keywordList: [],
      loadMap: true,
      mapSize: {
        width: 450,
        height: 450,
      },
      mapLocation: {
        posX: 37.578801,
        posY: 126.966441,
      },
      headerOption: {
        navOrder: 1,
      },
      form: {
      },
      memberInfo: {},
      pr: {
        logoAttchId: require("@/assets/images/thanqlogo.svg"),
        hospNm: "땡큐서울이비인후과의원",
        hospIntro:
          "지난 한세기 땡큐서울이비인후과의원은 국민의 건강과 생명을 지키고, 우리나라 의학발전을 선도해 왔습니다.",
        conConts:
          "지난 한세기 땡큐서울이비인후과의원은 국민의 건강과 생명을 지키고, 우리나라 의학발전을 선도해 왔습니다.",
        drPicAttchId: require("@/assets/images/doctorHa.jpeg"),
        drNm: "하정훈",
        drHist: "두경부암전공 수술횟수39999건",
        doctIntro:
          "땡큐서울이비인후과의원은 인류의 건강을 수호하는 세계 속의 병원으로 힘차게 전진해 나가고 있으며, 특히 ‘BreakThrough21 대한민국 의료를 세계로’라는 비전을 선포하고 환자중심의 병원으로 거듭나고자 한뜻으로 매진하고 있습니다.",
        addr: "서울 강남구 테헤란로 337(역삼동 705-27)",
        addrDtl: "화남빌딩 2층(외래), 3층(입원ㆍ수술)",
        hospTel: "02-562-5075",
        hospFaxNo: "02-563-5075",
        etcConts:
          "평일 오전9시-오후 6시 (점심시간 오후 1시-2시) 토요일 오전9시-오후1시 일요일·공휴일 휴진",
        mapAttchId: require("@/assets/images/mapmap.png"),
        picAttchId: require("@/assets/images/hsPicture.png"),
        rsvUrl: "www.thanqseoul.com",
        homepage: "www.thanqHome.com",
        etcPage: [
          {
            type: "블로그",
            value: "www.blog.com",
          },
          {
            type: "페이스북",
            value: "www.facebook.com",
          },
          {
            type: "트위터",
            value: "www.twitter.com",
          },
        ],
      },
      view: {
        pageOrder: this.$route.params.code,
      },
    };
  },
  created() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    this.config();
    this.getSearchData();
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("search", ["GET_SEARCH"]),
    computedLine() {
      return (text) => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      };
    },
  },
  methods: {
    ...mapGetters("util", ["GET_FORM_DATA"]),
    ...mapActions("search", ["FETCH_SEARCH_WITH_ID"]),
    ...mapActions("join", ["FETCH_BASIC_ADDRESS",]),
    config() {
      //console.log(this.GET_FORM_DATA())
      //console.log(localStorage.getItem("form"))
      this.form = JSON.parse(localStorage.getItem("form"));
      this.memberInfo = JSON.parse(localStorage.getItem("memberInfo"));
      this.view = JSON.parse(localStorage.getItem("view"));
      // console.log(this.form.sampleType.value);
      // console.log(this.form.editor);
      this.getAddress(this.memberInfo.addr);
    },
    async getAddress(address) {
      let result;
      await this.FETCH_BASIC_ADDRESS(address).then((data) => {
        result = data.result;
        if (result === true) {
          this.mapLocation.posX = data.posX;
          this.mapLocation.posY = data.posY;
          this.loadMap = true;
        }
        /** API 통신해서 받아온 문자열 */
      });
      if (result === false) {
        this.SET_POPUP(true);
        this.popupSet.title = "";
        this.popupSet.content = "위치정보를 받아오지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        this.SET_ADDRESS_POPUP(false);
      }
    },
    prev() {
      localStorage.removeItem("view");
      localStorage.removeItem("form");
      localStorage.removeItem("memberInfo");
      window.close();
    },
    openHomepage() {
      const homepage =
        this.form.homepage.value.indexOf("http://") > -1 ||
        this.form.homepage.value.indexOf("https://") > -1
          ? this.form.homepage.value
          : this.form.homepage.value.indexOf("https://") > -1 
          ? "http://" + this.form.homepage.value
          : "https://" + this.form.homepage.value;
      window.open(homepage);
    },
    async getSearchData() {
      //console.log("this.$route.params.code : ", this.$route.params.code);

      if (
        localStorage.getItem("drId") === null ||
        localStorage.getItem("drId") === undefined
      )
        localStorage.setItem("drId", "");
      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!| ]{2,20}$/;

      if (!drIdBoo.test(localStorage.getItem("drId"))) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "회원정보를 서버에서 수신하지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }
      const searchParam = {
        drId: localStorage.getItem("drId"),
      };
      try {
        await this.FETCH_SEARCH_WITH_ID(searchParam).then(() => {
          // console.log("?", this.GET_SEARCH);

          if (this.GET_SEARCH.etcKeyword1 !== "") {
            if (this.GET_SEARCH.etcKeyword1 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword1);
            }
          }
          if (this.GET_SEARCH.etcKeyword2 !== "") {
            if (this.GET_SEARCH.etcKeyword2 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword2);
            }
          }
          if (this.GET_SEARCH.etcKeyword3 !== "") {
            if (this.GET_SEARCH.etcKeyword3 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword3);
            }
          }
          if (this.GET_SEARCH.etcKeyword4 !== "") {
            if (this.GET_SEARCH.etcKeyword4 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword4);
            }
          }
          if (this.GET_SEARCH.etcKeyword5 !== "") {
            if (this.GET_SEARCH.etcKeyword5 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword5);
            }
          }
          if (this.GET_SEARCH.etcKeyword6 !== "") {
            if (this.GET_SEARCH.etcKeyword6 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword6);
            }
          }

          if (this.GET_SEARCH.operNm1 !== "") {
            if (this.GET_SEARCH.operNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.operNm1);
            }
          }
          if (this.GET_SEARCH.operNm2 !== "") {
            if (this.GET_SEARCH.operNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.operNm2);
            }
          }
          if (this.GET_SEARCH.operNm3 !== "") {
            if (this.GET_SEARCH.operNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.operNm3);
            }
          }

          if (this.GET_SEARCH.diagNm1 !== "") {
            if (this.GET_SEARCH.diagNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.diagNm1);
            }
          }
          if (this.GET_SEARCH.diagNm2 !== "") {
            if (this.GET_SEARCH.diagNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.diagNm2);
            }
          }
          if (this.GET_SEARCH.diagNm3 !== "") {
            if (this.GET_SEARCH.diagNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.diagNm3);
            }
          }

          if (this.GET_SEARCH.equipNm1 !== "") {
            if (this.GET_SEARCH.equipNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.equipNm1);
            }
          }
          if (this.GET_SEARCH.equipNm2 !== "") {
            if (this.GET_SEARCH.equipNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.equipNm2);
            }
          }
          if (this.GET_SEARCH.equipNm3 !== "") {
            if (this.GET_SEARCH.equipNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.equipNm3);
            }
          }

          if (this.GET_SEARCH.symptomNm1 !== "") {
            if (this.GET_SEARCH.symptomNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.symptomNm1);
            }
          }
          if (this.GET_SEARCH.symptomNm2 !== "") {
            if (this.GET_SEARCH.symptomNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.symptomNm2);
            }
          }
          if (this.GET_SEARCH.symptomNm3 !== "") {
            if (this.GET_SEARCH.symptomNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.symptomNm3);
            }
          }
          // console.log("키워드리스트 확인", this.keywordList);
          // console.log("키워드리스트 갯수 확인", this.keywordList.length);
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "회원정보를 서버에서 수신하지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    this.SET_POPUP(false);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/pr";
@import "@/assets/scss/ViewPrWindow";
</style>
<style lang="scss">
.raw-html-embed {
  margin: 15px 0;
}

figure > img {
  max-width: 100%;
}
.pr-wrap.default
  .pr-context
  .back
  .container
  .hospital
  .info
  .article-wrap
  .article.hsPicrue
  .picture-wrap
  img {
  max-height: 400px !important;
  min-height: 207px !important;
  min-width: 250px !important;
}

.pr-wrap.special .pr-block .block-content.hsPicrue .picture-wrap,
.pr-wrap.special .pr-block .block-content.hsMap .picture-wrap {
  width: 100% !important;
}

.item.prepare {
  .content-empty {
    width: 570px !important;
    margin-top: 30px !important;
    h4.title {
      font-size: 26px !important;
      position: relative;
      top: 10px !important;
      margin-bottom: 10px !important;
    }
    .img-wrap {
      img {
        width: 200px;
      }
    }
    .img_map {
      width: 430px !important;
    }

    .keyword-wrap {
      min-width: 650px !important;
      margin-left: -40px !important;
      position: relative;
      top: 10px !important;
    }
    .keyword-chip {
      margin-top: 8px;
      height: 40px;
      font-size: 18px;
      margin-right: 4px;
    }
    .introduce-text {
      max-width: 600px !important;
    }
    .phone-text {
      margin-top: 20px !important;
    }
    .location-text {
      width: 570px;
      text-align: center;
      padding: 0 0 0 20px;
    }
    .location-text-in {
      font-size: 26px;
    }

    .map-area {
      margin-top: 10px;
      margin-left: -90px;
    }
    .diag-introduce,
    .phone-text,
    .location-introduce,
    .homepage-text {
      margin-left: -90px !important;
    }

    .location-introduce {
      margin-top: 10px !important;
    }
  }
}

.pr-context {
  position: relative;
  top: -30px;
}

.mapText {
  font-size: 26px;
}

.pr-wrap-type-c {
  position: relative;
}

@media screen and (min-width: 992px) {
  .ab-btb-wrap-type-a {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-b {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-c {
    top: 91.5% !important;
  }
  .ab-btb-wrap-type-e {
    top: 94% !important;
  }
  .ab-btb-wrap-type-d {
    top: 94.5% !important;
  }
}

@media screen and (max-width: 991px) {
  .pr-wrap.special .pr-block .pre-content h3.title {
    text-align: left !important;
  }
  .pr-block.align-center.first {
    .block-content.doctor-profile {
      margin: 10px !important;
      p {
        margin: 0px 10px 0 0 !important;
      }
    }
    .pre-content1 {
      margin: 0px !important;
    }
  }

  .pr-wrap.special .pr-block .block-content,
  .pr-wrap.special .pr-block .pre-content {
    margin: 0 50px 0px 50px !important;
    width: 100% !important;
  }

  .pr-wrap.special
    .pr-block
    .block-content
    .article
    .a-content.input-grid
    .url-list
    p {
    margin: 0px 30px 0 0 !important;
  }
  .pr-wrap.special .pr-block .pre-content h3.title {
    padding: 0 !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 767px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 750px !important;
      }
    }
    .img_map {
      width: 400px !important;
    }
    .map-area {
      margin-left: -65px;
    }
    .diag-introduce,
    .phone-text,
    .location-introduce,
    .homepage-text {
      margin-left: -70px !important;
    }
  }

  .ab-btb-wrap-type-a {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-b {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-c {
    top: 90.5% !important;
  }
  .ab-btb-wrap-type-e {
    top: 94% !important;
  }
  .ab-btb-wrap-type-d {
    top: 93% !important;
  }
}

@media screen and (max-width: 975px) {
  .ab-btb-wrap-type-d {
    top: 93.5% !important;
  }
  .ab-btb-wrap-type-a {
    top: 85% !important;
  }
  .ab-btb-wrap-type-d {
    top: 94% !important;
  }
}

@media screen and (max-width: 783px) {
  .pr-wrap.special
    .pr-block
    .block-content.doctor-profile
    .d-pircture
    .img-wrap {
    width: 100% !important;
    img {
      margin: auto !important;
    }
  }
  .pr-wrap.default .pr-context .front .container div.title {
    h2 {
      width: 100% !important;
      text-align: center !important;
    }
    .img-wrap {
      img {
        max-width: 50% !important;
      }
    }
  }

  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    min-height: 820px !important;
    width: 604px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 580px !important;
      }
    }
    .item.prepare {
      .content-empty {
        width: 450px !important;
        margin-top: 60px !important;
        h4.title {
          font-size: 20px !important;
          position: relative;
          top: -15px !important;
          margin-bottom: 0px !important;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
        .img_map {
          width: 330px !important;
        }
        .keyword-wrap {
          min-width: 470px !important;
          margin-left: 0px !important;
          top: -10px !important;
        }
        .keyword-chip {
          font-size: 13px;
          height: 30px;
          padding-right: 20px;
        }
        .introduce-text {
          max-width: 500px !important;
        }
        .location-introduce {
          max-width: 450px !important;
        }

        .location-text {
          width: 450px;
          text-align: center;
          padding: 0 0 0 20px;
        }
        .location-text-in {
          font-size: 20px;
          position: relative;
          top: -28px !important;
          margin-left: -22px !important;
        }
        .location-introduce {
          margin-top: -7px !important;
        }
        .map-area {
          margin-left: -20px;
        }
        .diag-introduce,
        .phone-text,
        .location-introduce,
        .homepage-text {
          margin-left: -23px !important;
        }
      }
    }
  }

  .ab-btb-wrap-type-a {
    position: relative;
    top: 81% !important;
  }
  .ab-btb-wrap-type-d {
    top: 95% !important;
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
}

@media screen and (max-width: 766px) {
  .ab-btb-wrap-type-b {
    position: relative;
    top: 80% !important;
  }
  .ab-btb-wrap-type-d {
    top: 95% !important;
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
}

@media screen and (max-width: 604px) {
  .pr-wrap.default
    .pr-context
    .back
    .container
    .hospital
    .info
    .article-wrap.default {
    margin: 50px 0 0 0px !important;
    width: 100% !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 550px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 540px !important;
      }
    }

    .introduce-text {
      max-width: 450px !important;
    }
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
  .ab-btb-wrap-type-d {
    top: 95% !important;
  }
}

@media screen and (max-width: 576px) {
  /*
  .ab-btb-wrap {
    top: 790px !important;
  }
  .pr-wrap.default .pr-context .front .container div.title .img-wrap {
    flex: 0 0 100% !important;
    img {
    }
  }
*/
  .ab-btb-wrap-type-e {
    top: 93% !important;
  }

  .pr-wrap.special .pr-block .block-content .article p.type {
    margin: 0px !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 430px !important;
    min-height: 800px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 320px !important;
      }
    }
    .item.prepare {
      .content-empty {
        width: 350px !important;
        margin-top: 30px !important;
        h4.title {
          font-size: 16px !important;
          position: relative;
          top: 0px !important;
          margin-bottom: -5px !important;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
        .img_map {
          width: 250px !important;
        }
        .keyword-wrap {
          min-width: 330px !important;
          margin-left: 0px !important;
          top: 10px !important;
        }
        .keyword-chip {
          font-size: 10px;
          height: 26px;
          padding-right: 10px;
        }

        .location-text {
          max-width: 350px;
          padding: 0 0 0 0;
          position: relative;
        }
        .location-text-in {
          font-size: 16px !important;
        }

        .location-introduce {
          max-width: 350px !important;
        }
        .phone-text {
          margin-top: 10px !important;
        }
      }
    }
    .circle-wrap {
      width: 200px !important;
    }
  }
  .mapText {
    font-size: 18px !important;
  }
  .ab-btb-wrap-type-c {
    position: relative;
    top: 92% !important;
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
}
@media screen and (max-width: 360px) {
  /*
  .ab-btb-wrap {
    top: 660px !important;
  }
*/
  .pr-wrap.default .pr-context .front .container div.title {
    h2 {
      min-width: 280px !important;
      text-align: center !important;
    }
    .img-wrap {
      img {
        max-width: 50% !important;
      }
    }
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 300px !important;
    min-height: 730px !important;
    margin: auto;

    .item .img-wrap {
      img {
        max-width: 300px !important;
      }
    }
    .item.prepare {
      .content-empty {
        h4.title {
          font-size: 13px !important;
          position: relative;
          top: 17px !important;
          margin-bottom: -26px !important;
        }
        .title-map {
          margin-top: -10px !important;
        }
        .img_map {
          margin-top: 10px;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
        .keyword-chip {
          font-size: 10px;
          height: 23px;
        }
      }
    }
    .circle-wrap {
      width: 150px !important;
    }
    .keyword-wrap {
      margin-top: 37px !important;
    }
  }
  .mapText {
    font-size: 13px !important;
  }
}

@media print {
  .print {
    zoom: 98% !important;
  }
}
</style>
<style scoped>
.divpop {
  position: fixed;
  right: 10px;
  z-index: 200;
}

.popCenter {
  top: 50%;
  transform: translateY(-50%);
}
</style>